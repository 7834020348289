define("discourse/plugins/discourse-circles/discourse/components/circles/member-actions", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/create-topic-button", "discourse/helpers/route-action", "discourse/lib/notification-levels", "discourse/plugins/discourse-circles/discourse/components/circles/notifications-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _createTopicButton, _routeAction, _notificationLevels, _notificationsButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CirclesMemberActions extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentCircle", [_service.service]))();
    #currentCircle = (() => (dt7948.i(this, "currentCircle"), void 0))();
    get createTopicLabel() {
      return this.hasDraft ? "topic.open_draft" : "topic.create";
    }
    static #_3 = (() => dt7948.n(this.prototype, "createTopicLabel", [(0, _object.computed)("hasDraft")]))();
    get createTopicClass() {
      let classNames = ["btn-default"];
      if (this.hasDraft) {
        classNames.push("open-draft");
      }
      return classNames.join(" ");
    }
    static #_4 = (() => dt7948.n(this.prototype, "createTopicClass", [(0, _object.computed)("hasDraft")]))();
    get canCreateTopic() {
      return this.currentCircle.userCanCreateTopic;
    }
    get hasDraft() {
      return !!this.currentUser?.has_topic_draft;
    }
    static #_5 = (() => dt7948.n(this.prototype, "hasDraft", [(0, _object.computed)("currentUser.has_topic_draft")]))();
    get isCategoryNotificationsButtonVisible() {
      return this.currentCircle.userIsMember;
    }
    static #_6 = (() => dt7948.n(this.prototype, "isCategoryNotificationsButtonVisible", [(0, _object.computed)("args.model.is_circle_member")]))();
    get categoryNotificationLevel() {
      if (this.currentUser?.indirectly_muted_category_ids?.includes(this.args.model.category.id)) {
        return _notificationLevels.NotificationLevels.MUTED;
      } else {
        return this.args.model.category.notification_level;
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "categoryNotificationLevel", [(0, _object.computed)("args.model.category.notification_level")]))();
    changeCategoryNotificationLevel(notificationLevel) {
      this.args.model.category.setNotification(notificationLevel);
    }
    static #_8 = (() => dt7948.n(this.prototype, "changeCategoryNotificationLevel", [_object.action]))();
    static #_9 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="circles-nav-actions">
          <CreateTopicButton
            @label={{this.createTopicLabel}}
            @canCreateTopic={{this.canCreateTopic}}
            @action={{routeAction "clickCreateTopicButton"}}
            @disabled={{this.createTopicButtonDisabled}}
            @btnClass={{this.createTopicClass}}
          />
    
          {{#if this.isCategoryNotificationsButtonVisible}}
            <CirclesNotificationsButton
              @value={{this.categoryNotificationLevel}}
              @category={{@model.category}}
              @onChange={{this.changeCategoryNotificationLevel}}
            />
          {{/if}}
        </div>
      
    */
    {
      "id": "i7GKHXQ0",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"circles-nav-actions\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@label\",\"@canCreateTopic\",\"@action\",\"@disabled\",\"@btnClass\"],[[30,0,[\"createTopicLabel\"]],[30,0,[\"canCreateTopic\"]],[28,[32,1],[\"clickCreateTopicButton\"],null],[30,0,[\"createTopicButtonDisabled\"]],[30,0,[\"createTopicClass\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"isCategoryNotificationsButtonVisible\"]],[[[1,\"        \"],[8,[32,2],null,[[\"@value\",\"@category\",\"@onChange\"],[[30,0,[\"categoryNotificationLevel\"]],[30,1,[\"category\"]],[30,0,[\"changeCategoryNotificationLevel\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@model\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/member-actions.js",
      "scope": () => [_createTopicButton.default, _routeAction.default, _notificationsButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CirclesMemberActions;
});