define("discourse/plugins/discourse-circles/discourse/controllers/circle-requests", ["exports", "@ember/object", "@ember/service", "discourse/controllers/group-requests", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/url", "discourse/plugins/discourse-circles/discourse/components/modal/membership-reason", "discourse/plugins/discourse-circles/discourse/models/circle"], function (_exports, _object, _service, _groupRequests, _ajax, _ajaxError, _url, _membershipReason, _circle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CircleRequestsController extends _groupRequests.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    loadMore() {
      this.findRequesters();
    }
    static #_3 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
    async acceptRequest(user) {
      await this.handleRequest({
        user_id: user.get("id"),
        accept: true
      });
      const userCount = this.model.user_count;
      this.model.set("user_count", userCount + 1);
      user.setProperties({
        request_accepted: true,
        request_denied: false
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "acceptRequest", [_object.action]))();
    async undoAcceptRequest(user) {
      await (0, _ajax.ajax)("/groups/" + this.get("model.id") + "/members.json", {
        type: "DELETE",
        data: {
          user_id: user.get("id")
        }
      });
      const userCount = this.model.user_count;
      this.model.set("user_count", userCount - 1);
      user.set("request_undone", true);
    }
    static #_5 = (() => dt7948.n(this.prototype, "undoAcceptRequest", [_object.action]))();
    async denyRequest(user) {
      const performDeny = async reason => {
        const response = await this.handleRequest({
          user_id: user.get("id"),
          accept: false,
          reason
        });
        if (response) {
          user.setProperties({
            request_accepted: false,
            request_denied: true
          });

          // redirects the page if the response contains a relative URL to a
          // message
          if (this.siteSettings.discourse_circles_redirect_to_message_after_deny && response.relative_url) {
            _url.default.routeTo(response.relative_url);
          }
        }
      };
      if (this.siteSettings.discourse_circles_ask_reason_when_denying_request) {
        this.modal.show(_membershipReason.default, {
          model: {
            circle: this.circleModel,
            // because controllers are singletons. ensures that the reason will
            // be empty
            reason: "",
            mode: "deny",
            onSubmit: performDeny
          }
        });
      } else {
        return await performDeny();
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "denyRequest", [_object.action]))();
    async handleRequest(data) {
      try {
        const response = await _circle.default.handleMembershipRequest(this.circleModel.category_id, data);
        if (this.model.request_count > 0) {
          this.model.set("request_count", this.model.request_count - 1);
        }
        return response;
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      }
    }
  }
  _exports.default = CircleRequestsController;
});