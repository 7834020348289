define("discourse/plugins/discourse-circles/discourse/controllers/circle-manage-profile", ["exports", "@glimmer/tracking", "@ember/application", "@ember/controller", "@ember/object", "@ember/service"], function (_exports, _tracking, _application, _controller, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CirclesManageProfileController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "name", [_tracking.tracked], function () {
      return null;
    }))();
    #name = (() => (dt7948.i(this, "name"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "disableSave", [_tracking.tracked], function () {
      return true;
    }))();
    #disableSave = (() => (dt7948.i(this, "disableSave"), void 0))();
    #oldSlug = null;
    get slug() {
      return this.model.slug;
    }
    canSave() {
      let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.disableSave = !value;
    }
    static #_5 = (() => dt7948.n(this.prototype, "canSave", [_object.action]))();
    onBeforeSave() {
      this.#oldSlug = this.slug;
    }
    static #_6 = (() => dt7948.n(this.prototype, "onBeforeSave", [_object.action]))();
    onAfterSave(savedModel) {
      if (this.slug !== this.#oldSlug) {
        this.router.transitionTo("circle.manage.profile", savedModel.slug_path_with_id);
      } else {
        (0, _application.getOwner)(this).lookup("route:circle").refresh();
      }
      this.#oldSlug = this.slug;
    }
    static #_7 = (() => dt7948.n(this.prototype, "onAfterSave", [_object.action]))();
  }
  _exports.default = CirclesManageProfileController;
});