define("discourse/plugins/discourse-circles/discourse/routes/circle-manage-profile", ["exports", "@ember/service", "discourse/routes/discourse", "I18n"], function (_exports, _service, _discourse, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CircleManageProfileRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "circles", [_service.service]))();
    #circles = (() => (dt7948.i(this, "circles"), void 0))();
    showFooter = true;
    titleToken() {
      return _I18n.default.t(`discourse_circles.circle.manage.profile.title_token`);
    }
    async beforeModel() {
      await this.circles.reloadCustomFields();
    }
    model() {
      return this.modelFor("circle");
    }
  }
  _exports.default = CircleManageProfileRoute;
});