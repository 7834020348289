define("discourse/plugins/discourse-circles/discourse/controllers/circles-index", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "I18n"], function (_exports, _tracking, _controller, _object, _service, _ajaxError, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CirclesIndexController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "circlesService", [(0, _service.service)("circles")]))();
    #circlesService = (() => (dt7948.i(this, "circlesService"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "application", [_controller.inject]))();
    #application = (() => (dt7948.i(this, "application"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "filter", [_tracking.tracked], function () {
      return null;
    }))();
    #filter = (() => (dt7948.i(this, "filter"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "type", [_tracking.tracked], function () {
      return null;
    }))();
    #type = (() => (dt7948.i(this, "type"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "circles", [_tracking.tracked], function () {
      return null;
    }))();
    #circles = (() => (dt7948.i(this, "circles"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "isLoading", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoading = (() => (dt7948.i(this, "isLoading"), void 0))();
    queryParams = (() => ["filter", "type", ...this.circlesService.customFilters.map(filter => {
      if (filter.type === "multiselect") {
        return {
          [filter.queryParameter]: {
            type: "array"
          }
        };
      }
      return filter.queryParameter;
    })])();
    #username = null;
    get types() {
      return this.circles?.extras?.type_filters?.map(type => ({
        id: type,
        name: _I18n.default.t(`discourse_circles.index.types.${type}.title`),
        description: _I18n.default.t(`discourse_circles.index.types.${type}.description`)
      }));
    }
    get isUserProfile() {
      return !!this.#username;
    }
    get isFilteringByTypeEnabled() {
      return this.types?.length > 0 && !this.isUserProfile;
    }
    get showNewCircleButton() {
      return this.circlesService.userCanCreateCircles && (!this.#username || this.currentUser.username === this.#username);
    }
    get filters() {
      return this.circlesService.customFilters;
    }
    static #_10 = (() => dt7948.n(this.prototype, "filters", [_tracking.cached]))();
    async loadCircles(params) {
      this.isLoading = true;
      this.#username = params?.username;
      const serializedParams = Object.fromEntries(Object.entries(params).map(_ref => {
        let [key, value] = _ref;
        if (Array.isArray(value)) {
          return [key, JSON.stringify(value)];
        }
        return [key, value];
      }));
      try {
        this.circles = await this.store.findAll("circle", serializedParams);
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      } finally {
        this.isLoading = false;
      }
    }
    get totalRows() {
      return this.circles?.totalRows;
    }
    loadMore() {
      this.circles && this.circles.loadMore();
    }
    static #_11 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
    newCircle() {
      this.router.transitionTo("circles.new");
    }
    static #_12 = (() => dt7948.n(this.prototype, "newCircle", [_object.action]))();
  }
  _exports.default = CirclesIndexController;
});