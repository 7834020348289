define("discourse/plugins/discourse-circles/discourse/components/circles/manage-ownership-button", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/d-button", "truth-helpers/helpers/or", "discourse/plugins/discourse-circles/discourse/components/modal/manage-advanced-ownership", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _dButton, _or, _manageAdvancedOwnership, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CircleManageOwnershipButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "updating", [_tracking.tracked], function () {
      return false;
    }))();
    #updating = (() => (dt7948.i(this, "updating"), void 0))();
    get disabled() {
      const model = this.args.model;
      if (!model) {
        return true;
      }
      if (this.args.mode === "add" || this.args.mode === "transfer") {
        return model.owners.length >= Math.max(model.member_group.user_count, model.staff_group.user_count);
      }
      if (this.args.mode === "remove") {
        return model.owners.length <= 1;
      }
      return true;
    }
    static #_5 = (() => dt7948.n(this.prototype, "disabled", [(0, _object.computed)("args.mode", "args.model.owners.length", "args.model.member_group.user_count", "args.model.staff_group.user_count")]))();
    showManageOwnershipModal() {
      this.modal.show(_manageAdvancedOwnership.default, {
        model: {
          circle: this.args.model,
          mode: this.args.mode
        }
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "showManageOwnershipModal", [_object.action]))();
    static #_7 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          @action={{this.showManageOwnershipModal}}
          class="circles-{{@mode}}-ownership-button btn-danger"
          @disabled={{or this.disabled this.updating}}
          @icon={{this.icon}}
          @label="discourse_circles.circle.actions.ownership.{{@mode}}"
        />
      
    */
    {
      "id": "7VaN00yK",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[16,0,[29,[\"circles-\",[30,1],\"-ownership-button btn-danger\"]]]],[[\"@action\",\"@disabled\",\"@icon\",\"@label\"],[[30,0,[\"showManageOwnershipModal\"]],[28,[32,1],[[30,0,[\"disabled\"]],[30,0,[\"updating\"]]],null],[30,0,[\"icon\"]],[29,[\"discourse_circles.circle.actions.ownership.\",[30,1]]]]],null],[1,\"\\n  \"]],[\"@mode\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/manage-ownership-button.js",
      "scope": () => [_dButton.default, _or.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CircleManageOwnershipButton;
});