define("discourse/plugins/discourse-circles/discourse/services/circles", ["exports", "@ember/service", "discourse/lib/ajax", "discourse/models/category", "discourse/plugins/discourse-circles/discourse/models/circle"], function (_exports, _service, _ajax, _category, _circle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const circlesMap = {};
  class Circles extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    constructor() {
      super(...arguments);
      _circle.default.updateCustomFieldsAttributes(this.availableCustomFields);
    }
    get isCirclesEnabled() {
      return this.siteSettings.discourse_circles_enabled;
    }
    get userCanUseCircles() {
      if (!this.isCirclesEnabled) {
        return false;
      }
      if (!this.currentUser && this.siteSettings.login_required) {
        return false;
      }
      if (this.siteSettings.discourse_circles_allow_anonymous_use) {
        return true;
      }
      return !!this.currentUser?.can_use_circles;
    }
    get userCanCreateCircles() {
      return this.userCanUseCircles && !!this.currentUser?.can_create_circles;
    }
    userCanCreateTopic(circleModel) {
      return this.userCanUseCircles && !!circleModel?.can_create_topic;
    }
    userIsMember(circleModel) {
      return !!circleModel?.is_circle_member;
    }
    get availableCustomFields() {
      return this.site.circle_fields || [];
    }
    get searchableCustomFields() {
      return this.availableCustomFields.filter(field => field.searchable);
    }
    async reloadCustomFields() {
      const response = await (0, _ajax.ajax)("/circles/custom-fields");
      this.site.circle_fields = response.custom_fields;
      _circle.default.updateCustomFieldsAttributes(this.availableCustomFields);
      return this.availableCustomFields;
    }
    get customFilters() {
      return this.searchableCustomFields.map(field => ({
        id: field.id,
        name: field.name,
        queryParameter: `filter_${field.name}`,
        type: field.field_type,
        options: field.options
      }));
    }
    storeCircle(circle) {
      const circleId = circle?.category_id;
      if (!circleId) {
        return;
      }

      // this makes sure the category is available if it was prefetched because of the
      // Circles plugin

      // This is no longer necessary if lazy_load_categories is enabled.
      // TODO: (saquetim) adapt this to work properly with lazy_load_categories
      if (!_category.default.findById(circle.category_id)) {
        this.site.updateCategory(circle.category || {
          id: circle.category_id,
          is_circle: true,
          name: circle.name,
          slug: circle.slug,
          color: circle.color,
          read_restricted: circle.privacy_level > 0
        });
      }
      circle.category = _category.default.findById(circle.category_id);
      circlesMap[circleId] = circle;
    }
    storeCircles(circles) {
      if (!circles) {
        return;
      }
      circles.forEach(circle => {
        this.storeCircle(circle);
      });
    }
    findCached(categoryId) {
      return circlesMap[categoryId];
    }
  }
  _exports.default = Circles;
});